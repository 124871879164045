import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'app/service/chat/chat.service';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ChannelItem, ChatMessage } from '../../../../service/chat/model/chat-models';
import { ChatMessageInput } from './chat-body-input/chat-body-input.component';

const HOST_MESSAGE_THEME: ChatMessageTheme = {
  background: 'var(--app-syncee-primary-700)',
  textColor: 'var(--app-white-500)',
};

const RECIPIENT_MESSAGE_THEME: ChatMessageTheme = {
  background: 'var(--app-syncee-grey-200)',
  textColor: 'var(--app-black-500)',
};

@Component({
  selector: 'app-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss'],
})
export class ChatBodyComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollDesk', { static: false }) scrollDesk: ElementRef<HTMLElement>;

  @ViewChild('messageContainer', { static: false }) messagesContainer: ElementRef<HTMLElement>;

  @Input() currUserId: string;
  @Input() channel: ChannelItem;
  @Input() isInnerChat = false;

  @Input() channelSideHidden: boolean;
  @Output() backClick = new EventEmitter<void>();

  @Output() sentNewMessage = new EventEmitter<void>();
  noMessagesYet = false;

  messagesLoading = true;

  messagesError = false;

  hostMessageTheme = HOST_MESSAGE_THEME;
  recipientMessageTheme = RECIPIENT_MESSAGE_THEME;
  useFallbackAvatar: boolean;

  page = 0;
  size = 20;
  isMobile = false;
  messages: ChatMessage[] = [];
  groupedMessages: MessagesGroupedByDate[] = [];
  scrollable = true;

  curScrollPos = 0;
  prevScroll = 0;
  baseScrollHeight = 0;

  socket: WebSocket;
  subs: Subscription[] = [];

  constructor(private chatService: ChatService, private changeDet: ChangeDetectorRef, private datePipe: DatePipe) {
    this.isMobile = this.chatService.isMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.messagesLoading = true;
    this.getMessages(0, 20, false)
      .pipe(
        first(),
        tap((messages: ChatMessage[]) => {
          this.messages = messages;
          this.handleMessageGrouping();
          this.messagesLoading = false;
          this.messagesError = false;
        }),
        filter((messages: ChatMessage[]) => messages.length > 0),
        switchMap((messages: ChatMessage[]) => this.confirmMessage(_.last(messages).id)),
        switchMap((messages: ChatMessage[]) => this.chatService.unseenMessageForce())
      )
      .subscribe(
        () => {
          this.channel.unseenMessageCount = 0;
        },
        () => {
          this.messagesLoading = false;
          this.messagesError = true;
        }
      );
    this.initMessageHandler();
  }

  ngOnInit(): void {}

  initMessageHandler(): void {
    this.unsubscribeFromMessageHandler();
    this.chatService.initMessageReceiver(this.channel.id);
    this.subs.push(
      this.chatService
        .messageReceiveEvent()
        .pipe(
          tap((message) => {
            this.scrollable = true;
            this.messages.push(message);
          }),
          debounceTime(1000)
        )
        .subscribe((message: ChatMessage) => {
          this.confirmMessage(message.id).subscribe();
        })
    );
  }

  confirmMessage(messageId): Observable<any> {
    return this.chatService.confirmMessage(messageId).pipe(first());
  }

  getMessages(page = 0, size = 20, append = true): Observable<ChatMessage[]> {
    this.page = page;
    return this.chatService.getChannelMessages(this.channel.id, page, size).pipe(
      tap((messages) => {
        this.noMessagesYet = page === 0 && isEmpty(messages);
      }),
      map((messages) => {
        return messages.reverse();
      })
    );
  }

  newMessageHandler(input: ChatMessageInput): void {
    this.chatService.sendMessage(this.channel.id, input.message, input.media && input.media.file).subscribe((mess) => {
      this.getMessages().subscribe((value) => {
        this.messages = value;
        this.handleMessageGrouping();
        this.sentNewMessage.emit();
      });
    });
  }

  onScrollHandle(): void {
    this.scrollable = false;
    this.getMessages(this.page + 1, this.size)
      .pipe(first())
      .subscribe((messages) => {
        this.curScrollPos = this.scrollDesk.nativeElement.scrollTop;
        this.prevScroll = this.scrollDesk.nativeElement.scrollHeight - this.scrollDesk.nativeElement.clientHeight;
        this.messages = messages.concat(this.messages);
        this.handleMessageGrouping();
      });
  }

  unsubscribeFromMessageHandler(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  handleBackClick(): void {
    this.backClick.emit();
  }

  private handleMessageGrouping(): void {
    this.groupedMessages = this.groupMessagesByDate(this.messages);
  }

  private groupMessagesByDate(messages: ChatMessage[]): MessagesGroupedByDate[] {
    const grouped = messages.reduce((groups, message) => {
      const date = message.date.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
    return Object.keys(grouped).map((date) => {
      return {
        date: date,
        messages: grouped[date],
      };
    });
  }

  ngOnDestroy(): void {
    this.chatService.closeMessageReceiver();
    this.unsubscribeFromMessageHandler();
  }
}
export interface ChatMessageTheme {
  background: string;
  textColor: string;
}

export interface MessagesGroupedByDate {
  date: string;
  messages: ChatMessage[];
}
