import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationEventService {
  private navigationStart$ = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart && event?.navigationTrigger === 'imperative'),
        tap(() => console.warn('on nav start'))
      )
      .subscribe(() => {
        this.navigationStart$.next();
      });
  }

  get onNavigationStart(): Observable<void> {
    return this.navigationStart$.asObservable();
  }
}
