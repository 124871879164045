import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BoolOptions,
  SupplierTaxSettingsService,
  TaxRate,
} from '../../../service/supplier-tax-settings/supplier-tax-settings.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddSupplierTaxSettingsDialogComponent } from './add-supplier-tax-settings-dialog/add-supplier-tax-settings-dialog.component';
import { NotificationService } from '../../../main/notification/notification.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EU_COUNTRIES_CODES, STATES } from '../../../utils/Constants';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { CustomTableComponent } from '../custom-table/custom-table.component';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-supplier-tax-settings',
  templateUrl: './supplier-tax-settings.component.html',
  styleUrls: ['./supplier-tax-settings.component.scss'],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    FlexModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    CustomTableComponent,
  ],
  standalone: true,
})
export class SupplierTaxSettingsComponent implements OnInit, OnDestroy {
  private _ready = false;

  private _usStates = STATES;

  private _boolOptions: BoolOptions[] = [
    new BoolOptions(true, 'SUPPLIER_TAX_SETTINGS.BOOL_OPTION_TRUE'),
    new BoolOptions(false, 'SUPPLIER_TAX_SETTINGS.BOOL_OPTION_FALSE'),
  ];

  private _formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  private _settingsForm: UntypedFormGroup;
  private _USSettingsForm: UntypedFormGroup;

  private _taxSettings: TaxRate[] = [];

  private _defaultLocation: string;

  private _defaultLocationCode: string;

  private _unsubscribeAll = new Subject<void>();

  private _hasPayPalEmail = true;

  private _inEurope: boolean;

  private _inAustralia: boolean;

  private _inUS: boolean;

  private _isCanada: boolean;

  dataSource: MatTableDataSource<TaxRate>;
  displayedColumns = ['CountryName', 'TaxRate', 'Delete'];

  constructor(
    private supplierTaxSettingsService: SupplierTaxSettingsService,
    private countriesManagerService: CountriesManagerService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private notiService: NotificationService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.initSettingsForm();
    // this.supplierTaxSettingsService.payPalEmailChange.subscribe(email => {
    //   this._hasPayPalEmail = email.length !== 0;
    // });
  }

  private initSettingsForm(): void {
    forkJoin(this.supplierTaxSettingsService.getWarehouseLocation(), this.supplierTaxSettingsService.getTaxSettings())
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((results) => {
        this.defaultLocation = results[0].mainWarehouseLocation;
        this._defaultLocationCode = this.countriesManagerService.getCountryCodeByCountryName(this.defaultLocation);
        this._inEurope = EU_COUNTRIES_CODES.includes(this.defaultLocationCode) || this.defaultLocationCode === 'GB';
        this._inAustralia = this.defaultLocationCode === 'AU';
        this._inUS = this.defaultLocationCode === 'US';
        this._isCanada = this.defaultLocationCode === 'CA';

        this.settingsForm = this.formBuilder.group({
          productHasTax: [null, [Validators.required]],
          addTaxToOrder: [null],
          shippingHasTax: [null],
          defaultLocationTaxRate: [0, this.supplierTaxSettingsService.validateTaxRate()],
        });

        this.initTaxSettings(results[1].settings);
        this.initTaxRates(results[1].taxRate);

        this.initSettingsSubscription();

        if (this.isInUS) {
          this.initUSSettingsForm(results[0].companyAddress);
        } else {
          this.isReady = true;
        }
      });
  }

  private initTaxSettings(settings): void {
    if (!Utils.isNullOrUndefined(settings) && settings.length !== 0) {
      this.settingsForm.patchValue({
        productHasTax: settings.productHasTax,
        shippingHasTax: settings.shippingHasTax,
        addTaxToOrder: settings.taxOrders,
      });
    }
  }

  private initUSSettingsForm(companyAddress: any): void {
    this.USSettingsForm = this.formBuilder.group({
      avalaraAccountId: ['', Validators.required],
      avalaraLicenceKey: ['', Validators.required],
      avalaraCompanyCode: ['', Validators.required],
      city: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      zip: ['', Validators.required],
      province: ['', Validators.required],
    });

    this.supplierTaxSettingsService.getAvalaraAccount().subscribe((response) => {
      if (!Utils.isNullOrUndefined(response)) {
        this.USSettingsForm.patchValue({
          avalaraAccountId: response.id,
          avalaraLicenceKey: response.licenceKey,
          avalaraCompanyCode: response.companyCode,
          city: companyAddress.city,
          address1: companyAddress.address1,
          address2: companyAddress.address2,
          zip: companyAddress.zip,
          province: this._usStates.find((item) => item.abbreviation === companyAddress.provinceCode),
        });
      }
      this.isReady = true;
    });
  }

  private initTaxRates(taxRates): void {
    if (!Utils.isNullOrUndefined(taxRates) && taxRates.length !== 0) {
      let defaultTaxRate = 0;
      taxRates.forEach((item) => {
        if (item.country === this.defaultLocationCode) {
          defaultTaxRate = item.taxRate;
        } else {
          this.taxSettings = [
            ...this.taxSettings,
            new TaxRate(
              item.country,
              this.countriesManagerService.getCountryNameByCountryCode(item.country),
              item.taxRate
            ),
          ];
        }
      });

      this.settingsForm.patchValue({ defaultLocationTaxRate: defaultTaxRate });
    }
  }

  public addTaxRate(): void {
    const dialogConf = new MatDialogConfig();
    const alreadyMapped = this.taxSettings.map((item) => item.countryCode);
    dialogConf.data = {
      countries: this.countriesManagerService
        .getOnlyCountries()
        .filter(
          (country) =>
            !alreadyMapped.includes(country.code) &&
            country.code !== this.defaultLocationCode &&
            (EU_COUNTRIES_CODES.includes(country.code) || country.code === 'GB')
        ),
    };
    dialogConf.width = '520px';
    dialogConf.maxWidth = '100%';

    const dialogRef = this.dialog.open(AddSupplierTaxSettingsDialogComponent, dialogConf);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult !== null && dialogResult instanceof TaxRate) {
        this.taxSettings = [...this.taxSettings, dialogResult];
      }
    });
  }

  public deleteTaxRate(index: any): void {
    this.taxSettings = this.taxSettings.filter((item, i) => index !== i);
  }

  public save(): void {
    if (this.settingsForm.valid) {
      const defaultLocationTaxRate = new TaxRate(
        this.defaultLocationCode,
        this.defaultLocation,
        parseInt(this.settingsForm.get('defaultLocationTaxRate').value, 10)
      );
      let taxSettings = [];
      if (this.showTaxTable) {
        taxSettings = [...this.taxSettings, defaultLocationTaxRate];
      }
      if (this.isInAustralia) {
        taxSettings = [defaultLocationTaxRate];
      }
      this.saveSettings(taxSettings).subscribe(() => {
        this.notiService.success(this.translate.instant('SUPPLIER_TAX_SETTINGS.SAVE_SUCCESS'));
      });
    } else {
      this.settingsForm.markAllAsTouched();
    }
  }

  private saveSettings(taxSettings: TaxRate[]): Observable<void> {
    return this.supplierTaxSettingsService.saveTaxSettings(
      this.settingsForm.get('productHasTax').value,
      Utils.isNullOrUndefined(this.settingsForm.get('shippingHasTax').value)
        ? false
        : this.settingsForm.get('shippingHasTax').value,
      Utils.isNullOrUndefined(this.settingsForm.get('addTaxToOrder').value)
        ? false
        : this.settingsForm.get('addTaxToOrder').value,
      taxSettings
    );
  }

  public saveUSSettings(): void {
    if (this.settingsForm.valid) {
      this.saveSettings([]).subscribe(() => {
        if (!this.showTaxTable) {
          this.notiService.success(this.translate.instant('SUPPLIER_TAX_SETTINGS.SAVE_SUCCESS'));
        }
      });

      if (this.showTaxTable) {
        this.supplierTaxSettingsService
          .saveUSSettings(
            this.USSettingsForm.get('avalaraAccountId').value,
            this.USSettingsForm.get('avalaraLicenceKey').value,
            this.USSettingsForm.get('avalaraCompanyCode').value,
            this.USSettingsForm.get('city').value,
            this.USSettingsForm.get('address1').value,
            this.USSettingsForm.get('address2').value,
            this.USSettingsForm.get('zip').value,
            this.USSettingsForm.get('province').value
          )
          .subscribe(() => this.notiService.success(this.translate.instant('SUPPLIER_TAX_SETTINGS.SAVE_SUCCESS')));
      }
    } else {
      this.settingsForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private initSettingsSubscription(): void {
    this.settingsForm.controls['productHasTax'].valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((productHasTax: boolean): void => {
        console.log(productHasTax);
        if (productHasTax) {
          this.settingsForm.controls['addTaxToOrder'].setValidators([]);
          this.settingsForm.controls['addTaxToOrder'].updateValueAndValidity();
          this.settingsForm.controls['shippingHasTax'].setValidators([]);
          this.settingsForm.controls['shippingHasTax'].updateValueAndValidity();
        } else {
          this.settingsForm.controls['addTaxToOrder'].setValidators([Validators.required]);
          this.settingsForm.controls['addTaxToOrder'].updateValueAndValidity();
        }
      });

    this.settingsForm.controls['addTaxToOrder'].valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((productHasTax: boolean): void => {
        if (productHasTax) {
          this.settingsForm.controls['shippingHasTax'].setValidators([Validators.required]);
          this.settingsForm.controls['shippingHasTax'].updateValueAndValidity();
        } else {
          this.settingsForm.controls['shippingHasTax'].setValidators([]);
          this.settingsForm.controls['shippingHasTax'].updateValueAndValidity();
        }
      });
  }

  get defaultLocationCode(): string {
    return this._defaultLocationCode;
  }

  get isInEurope(): boolean {
    return this._inEurope;
  }

  get isInUS(): boolean {
    return this._inUS;
  }

  get isInAustralia(): boolean {
    return this._inAustralia;
  }

  get hasPayPalEmail(): boolean {
    return this._hasPayPalEmail;
  }

  get isReady(): boolean {
    return this._ready;
  }

  set isReady(value: boolean) {
    this._ready = value;
  }

  get formBuilder(): UntypedFormBuilder {
    return this._formBuilder;
  }

  set formBuilder(value: UntypedFormBuilder) {
    this._formBuilder = value;
  }

  get settingsForm(): UntypedFormGroup {
    return this._settingsForm;
  }

  set settingsForm(value: UntypedFormGroup) {
    this._settingsForm = value;
  }

  get USSettingsForm(): UntypedFormGroup {
    return this._USSettingsForm;
  }

  set USSettingsForm(value: UntypedFormGroup) {
    this._USSettingsForm = value;
  }

  get boolOptions(): BoolOptions[] {
    return this._boolOptions;
  }

  get taxSettings(): any {
    return this._taxSettings;
  }

  set taxSettings(value: any) {
    this._taxSettings = value;
    this.dataSource.data = value;
  }

  get showTaxTable(): boolean {
    const addTaxToOrder = this.settingsForm.get('addTaxToOrder').value;
    return !this.settingsForm.get('productHasTax').value && !Utils.isNullOrUndefined(addTaxToOrder) && addTaxToOrder;
  }

  get showAddTaxToOrder(): boolean {
    const productHasTax = this.settingsForm.get('productHasTax').value;
    return !Utils.isNullOrUndefined(productHasTax) && !productHasTax;
  }

  get defaultLocation(): any {
    return this._defaultLocation;
  }

  set defaultLocation(value: any) {
    this._defaultLocation = value;
  }

  get unsubscribeAll(): Subject<void> {
    return this._unsubscribeAll;
  }

  get usStates(): any {
    return this._usStates;
  }

  get isCanada(): boolean {
    return this._isCanada;
  }
}
